import merge from 'deepmerge';
import { tint } from 'polished';

import defaultConfig from './default.json';

export type ThemeConfig = typeof defaultConfig;

type ThemeColors = Pick<ThemeConfig, 'colors'>;

type Colors = Omit<ThemeColors['colors'], 'base'> & {
    base: string | Record<string, string | undefined>;
};

interface WidgetContainerProps {
    border?: string;
    showHeader?: boolean;
    fontFamily?: string;
    headerColor?: string;
}

export interface Theme extends Omit<ThemeConfig, 'colors'> {
    radii: number[];
    fontImportUrl?: string;
    widgetContainer?: WidgetContainerProps;
    colors: Colors;
}

interface ThemeOverrides extends DeepPartial<Omit<Theme, 'widgetContainer'>> {
    widgetContainer?: WidgetContainerProps | string;
}

const overwriteMerge = (destinationArray, sourceArray) =>
    sourceArray.length > 0 ? sourceArray : destinationArray;

export type DeepPartial<T> = T extends Function
    ? T
    : T extends object
    ? { [P in keyof T]?: DeepPartial<T[P]> }
    : T;

export const generateBaseTints = (color: string) =>
    Array.from(Array(21))
        .map((_, i) => i * 5)
        .reduce(
            (acc, value) => ({
                ...acc,
                [value]: tint((100 - value) / 100, color),
            }),
            {},
        ) as ThemeConfig['colors']['base'];

export const getTheme = (
    themeOverrides?: ThemeOverrides,
    useBaseOverrides: boolean = false,
): ThemeConfig & DeepPartial<Theme> => {
    const { fontImportUrl, widgetContainer, ...overrides } = themeOverrides || {};

    const theme = merge<ThemeConfig, DeepPartial<Theme>>(defaultConfig, overrides, {
        arrayMerge: overwriteMerge,
    });

    if (typeof themeOverrides?.colors?.base === 'string') {
        theme.colors.base = generateBaseTints(themeOverrides.colors.base);
    } else if (typeof themeOverrides?.colors?.base === 'object') {
        theme.colors.base = { ...defaultConfig.colors.base, ...themeOverrides.colors.base };
    } else if (!useBaseOverrides) {
        theme.colors.base = defaultConfig.colors.base;
    }

    if (typeof widgetContainer === 'string') {
        try {
            theme.widgetContainer = JSON.parse(decodeURI(widgetContainer));
        } catch (_) {
            console.error('Improper widget container prop being passed');
        }
    } else if (typeof widgetContainer === 'object') {
        theme.widgetContainer = widgetContainer;
    }

    if (typeof fontImportUrl === 'string' && fontImportUrl !== '') {
        theme.fontImports = [...theme.fontImports, fontImportUrl];
    }

    if (theme.colourScheme === 'dark') {
        theme.colors.main.background = theme.colors.dark;
        theme.colors.main.typography = theme.colors.light;
    } else {
        theme.colors.main.background = theme.colors.light;
        theme.colors.main.typography = theme.colors.dark;
    }

    return theme;
};

export default defaultConfig;
