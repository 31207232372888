import { rest } from 'msw';

const updatePassword = rest.post('/auth/update-password', (req, res, ctx) => {
    const expiredPasswordData = {
        id: 'expired_id',
        res: { message: 'Looks like your link for updating password expired' },
    };
    let statusCode = 200;
    let response = {};
    let password;
    let changePasswordId;

    if (typeof req.body === 'string') {
        ({ password, changePasswordId } = JSON.parse(req.body));
    } else {
        ({ password, changePasswordId } = req.body as {
            password: string;
            changePasswordId: string;
            termsAndConditionsAccepted: boolean;
        });
    }

    if (typeof changePasswordId === 'string' && !changePasswordId.length) {
        statusCode = 400;
        response = {
            errors: [{ message: 'Looks like password criteria were invalid' }],
        };
    }

    if (changePasswordId === expiredPasswordData.id) {
        statusCode = 401;
        response = {
            errors: [expiredPasswordData.res],
        };
    }

    if (typeof password === 'string' && !password.length) {
        statusCode = 406;
        response = {
            errors: [{ message: 'Looks like you provided an empty password' }],
        };
    }

    if (typeof password === 'string' && password === password.toLowerCase()) {
        statusCode = 406;
        response = {
            errors: [
                {
                    message:
                        'Looks like the password criteria were invalid, please type your password and try again',
                },
            ],
        };
    }

    return res(ctx.status(statusCode), ctx.json(response));
});

export default [updatePassword];
