import { ComponentType, memo } from 'react';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import loadable from '@loadable/component';

import RootLayout from '@components/Layout/RootLayout';
import isBrowser from '@utilities/isBrowser';

import { DomainRouter, DomainRoute } from './DomainRouter';

export const routesConfig = [
    {
        name: 'Circuit Hub',
        Feature: loadable.lib(() => import('@features/site-circuit-hub')),
        domain: /^(www.cctour.grid.gg|cctour.gg|circuithub|(watch-circuit-dev|watch-circuit-stg|watch-circuit.stg|watch-circuit).grid.gg)$/,
    },
    {
        name: 'Watch Hub',
        Feature: loadable.lib(() => import('@features/site-watch-hub')),
        domain: /^(watchhub|(watch-dev|watch-stg|watch.stg|watch).grid.gg)$/,
    },
    {
        name: 'Watch Pages',
        Feature: loadable.lib(() => import('@features/site-whitelabel-team')),
        domain: /^(wlteam|watch\..*[A-z]|(whitelabel-dev|whitelabel-stg|whitelabel.stg|whitelabel|demo-stg|demo.stg|endpoint|pinnacle|og|vexed|cphflames|sprout|big|g2|ence|c0ntact|movistarriders|gambit|smash|madlions|chaos|nordavind|homesweethome|fnatic|navi|astralis|mousesports|liquid|100thieves|furia|nip|mibr|forze|spirit|virtuspro|evilgeniuses|faze|vitality|north|watchpage-demo|ninetofive|back2warcraft|esm|maltavibes|skade|xkomago|fpx|cloud9|complexity|heroic|geng|teb|lootbet|alliance|flashpoint|epicleague|edc|funspark|lund|pinnaclecup|maestro|teamsecret|sefdota|sefvalorant|saudieleaguesdota|saudieleaguesvalorant).grid.gg)$/,
    },
    {
        name: 'Dashboard',
        Feature: loadable.lib(() => import('@features/dashboard')),
        domain: /^(valorant|valorant-dev|valorant.stg|lol.stg|r6.stg|r6|r6-dev|lol|lol-dev|portal|portal-dev|portal.stg).grid.gg$/,
    },
    {
        name: 'Widgets',
        Feature: loadable.lib(() => import('./widgetRoutes')),
        domain: /^widgets-dev.grid.gg$/,
    },
    {
        name: 'GRID Site',
        Feature: loadable.lib(() => import('@features/site-gridgg')),
        domain: /^(site-gridgg-dev.grid.gg|site-gridgg-stg.grid.gg|www.grid.gg|grid.gg)$/,
    },
];

interface RoutingProps {
    // Providers which depend on react-router's Router
    innerProviders: ComponentType<React.PropsWithChildren<unknown>>;
}

const Routing = memo(({ innerProviders: InnerProviders }: RoutingProps) => {
    const ReactRouter = isBrowser ? BrowserRouter : MemoryRouter;

    return (
        <DomainRouter>
            {routesConfig.map(({ name, Feature, domain }) => (
                <DomainRoute key={name} domain={domain}>
                    <ReactRouter>
                        <InnerProviders>
                            <Feature fallback={<RootLayout background={2} />}>
                                {({ default: { component: FeatureRoutes } }) => <FeatureRoutes />}
                            </Feature>
                        </InnerProviders>
                    </ReactRouter>
                </DomainRoute>
            ))}
        </DomainRouter>
    );
});

export default Routing;
