import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    ApolloLink,
    defaultDataIdFromObject,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { relayStylePagination } from '@apollo/client/utilities';
import config from '@config';

import { AUTH_TOKEN_COOKIE_KEY, getAuthToken } from '@features/authentication/utilities/authToken';

import typeDefs from './schema/schema.graphql';

const httpInternalCentralDataLink = createHttpLink({
    uri: `${config.hosts.gridInternalApi}/central-data/v2/graphql`,
});
const httpExternalCentralDataLink = createHttpLink({
    uri: `${config.hosts.gridApi}/central-data/graphql`,
});
const httpLiveDataFeedLink = createHttpLink({
    uri: `${config.hosts.gridApi}/live-data-feed/series-state/graphql`,
});
const httpEventsExplorerLink = createHttpLink({
    uri: '/api/event-explorer-api/events/graphql',
});
const httpUserConfigurationLink = createHttpLink({
    uri: `${config.hosts.gridInternalApiLegacy}/user-configurations/graphql`,
});

const httpStrapiLink = createHttpLink({ uri: `${config.hosts.gridApi}/content/graphql` });
const httpStrapiWebsiteLink = createHttpLink({
    uri: `${config.hosts.gridContent}/website/graphql`,
});
const httpStrapiArticleDocumentation = createHttpLink({
    uri: `${config.hosts.gridContent}/documentation/graphql`,
});
const httpStrapiProductUpdateLink = createHttpLink({
    uri: `${config.hosts.gridContent}/product-updates/graphql`,
});

const restLink = new RestLink({
    uri: '/api',
    endpoints: {
        dashboardApi: '/api',
        centralDataInternal: `${config.hosts.gridInternalApi}/central-data`,
        dpControls: `${config.hosts.gridApi}/controls-dp`,
        fileDownload: `${config.hosts.gridApi}/file-download`,
        sdkTelemetryApi: `${config.hosts.sdkApi}/telemetry`,
        statsExplorerApi: `${config.hosts.portal}/api/stats-explorer`,
        postGameApi: `${config.hosts.gridApi}/post-game-api`,
        postGameApiGateway: `${config.hosts.gridApi}/post-game-api-gateway`,
        authentication: `${config.hosts.portal}/auth`,
    },
    responseTransformer(resp?: Response, typeName?: string) {
        // Remember that this is for throwing 404 status code error
        // As by default gql does not threat 404 as networkError instead it sets data as null
        if (resp === null) {
            throw new Error(`${typeName}: Responds with body === null (could be 404 status code)`);
        }

        if (!resp?.json) {
            return resp;
        }

        return resp?.json();
    },
});

const authTokenMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            [AUTH_TOKEN_COOKIE_KEY]: `Bearer ${getAuthToken()}`,
        },
    }));

    return forward(operation);
});

const timezoneMiddleware = new ApolloLink((operation, forward) => {
    if (operation.operationName !== 'GetDashboardSeries') return forward(operation);
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            userTimeZoneOffset: (-1 * new Date().getTimezoneOffset()) / 60,
        },
    }));

    return forward(operation);
});

interface LinkConfig {
    name: string;
    link: ApolloLink;
}

type LinksConfig = LinkConfig[];

/**
 * Helper function for finding correct apollo link by link name with recurrency
 */
const findLinkByName = (links: LinksConfig): ApolloLink => {
    const [link, ...restLinks] = links;
    return ApolloLink.split(
        (operation) => {
            const currentLinkName = operation.getContext().linkName;
            const hasMatchingLinkName = currentLinkName === link.name;
            return hasMatchingLinkName;
        },
        link.link,
        restLinks.length === 1 ? restLinks[0].link : findLinkByName(restLinks),
    );
};

const links: LinksConfig = [
    { name: 'strapi', link: httpStrapiLink },
    { name: 'strapiWebsite', link: httpStrapiWebsiteLink },
    {
        name: 'strapiProductUpdate',
        link: ApolloLink.from([authTokenMiddleware, httpStrapiProductUpdateLink]),
    },
    {
        name: 'strapiArticleDocs',
        link: ApolloLink.from([authTokenMiddleware, httpStrapiArticleDocumentation]),
    },
    {
        name: 'seriesState',
        link: ApolloLink.from([authTokenMiddleware, httpLiveDataFeedLink]),
    },
    {
        name: 'externalCda',
        link: ApolloLink.from([authTokenMiddleware, httpExternalCentralDataLink]),
    },

    {
        name: 'eventsExplorer',
        link: ApolloLink.from([authTokenMiddleware, httpEventsExplorerLink]),
    },
    {
        name: 'userConfiguration',
        link: httpUserConfigurationLink, // user configuration link does not need auth token
    },
    {
        // Last entry will always be a default
        // DO NOT CHANGE until expect it
        name: 'default',
        link: ApolloLink.from([
            ApolloLink.from([authTokenMiddleware, timezoneMiddleware, restLink]),
            ApolloLink.from([authTokenMiddleware, httpInternalCentralDataLink]),
        ]),
    },
];

export const createClient = () =>
    new ApolloClient({
        connectToDevTools: true,
        link: findLinkByName(links),
        cache: new InMemoryCache({
            dataIdFromObject(responseObject) {
                // eslint-disable-next-line
                switch (responseObject.__typename) {
                    case 'DocumentationArticle':
                        return `DocumentationArticle:${responseObject.id || responseObject.vuid}`;
                    case 'Person':
                        return `Person:${responseObject.name}:${responseObject.email}`;
                    default:
                        return defaultDataIdFromObject(responseObject);
                }
            },
            typePolicies: {
                Query: {
                    fields: {
                        tournaments: relayStylePagination(['filter', ['name', ['contains']]]),
                        teams: relayStylePagination(['filter', ['name', ['contains']]]),
                        players: relayStylePagination([
                            'filter',
                            ['teamIdFilter', ['id']],
                            ['name', ['contains']],
                        ]),
                        events: relayStylePagination([
                            ['filter', ['event', ['type', ['eq']]]],
                            'id',
                        ]),
                    },
                },
            },
        }),
        typeDefs,
        defaultOptions: {
            query: {
                fetchPolicy:
                    process.env.NODE_ENV === 'development' ? 'network-only' : 'cache-first',
            },
        },
    });

export default createClient();
