import 'core-js/stable';
import '@webcomponents/shadydom/';
import 'whatwg-fetch';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';

import useBrandTheme from '@config/themes/useBrandTheme';
import { LoaderFullPage } from '@components/Misc/Loader';
import { AuthContextProvider } from '@features/authentication/context/authentication';
import { FeatureSwitchesProvider } from '@features/feature-switches/contexts/feature-switches';
import useSentry from '@hooks/useSentry';
import startMocks from '@mocks/startMocks';
import translation from '@translations/widgets.json';

import ApolloProvider from './apollo/provider';
import {
    GlobalFonts,
    GlobalEmotionReset,
    GlobalFontImports,
    DefaultFonts,
    GlobalGeneralStyles,
} from './globalStyles';
import Routing from './routing';

const ROOT_NAME = 'root';

function RoutingInnerProviders({ children }) {
    return <FeatureSwitchesProvider>{children}</FeatureSwitchesProvider>;
}

// Needs to be separate component from App to initialize Apollo for useBrandTheme
function AppWithApollo() {
    const { theme, isLoading } = useBrandTheme();
    useSentry();

    return (
        <ThemeProvider theme={theme}>
            <GlobalFonts />
            <GlobalFontImports />
            <GlobalEmotionReset />
            <DefaultFonts />
            <GlobalGeneralStyles />
            <AuthContextProvider>
                {isLoading ? (
                    <LoaderFullPage />
                ) : (
                    <Routing innerProviders={RoutingInnerProviders} />
                )}
            </AuthContextProvider>
        </ThemeProvider>
    );
}

function App() {
    useEffect(() => {
        i18n.use(initReactI18next).init({
            resources: { ...translation },
            lng: 'en',
            fallbackLng: 'en',
        });
    }, []);

    // TODO: [TECHDEBT] Disable StrictMode to avoid compatibility issues with react v18
    return (
        <ApolloProvider>
            <AppWithApollo />
        </ApolloProvider>
    );
}

if (!process.env.DISABLE_MSW && process.env.NODE_ENV === 'development') {
    startMocks();
}

const root = createRoot(document.getElementById(ROOT_NAME)!);
root.render(<App />);
