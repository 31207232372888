const liveSeriesData = require('./liveSeries.json');

export const upcomingSeries = {
    seriesId: '1',
    tournament: {
        name: 'Nine to Five',
    },
    games: [],
    series: {
        startTimeDate: new Date(1482363367071).toISOString(),
        endTimeDate: '',
        status: 'upcoming',
        title: 'csgo',
        teams: [
            {
                id: '1',
                name: 'Secret',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1649',
                score: 0,
                won: false,
            },
            {
                id: '2',
                name: 'Virtus.Pro',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1749',
                score: 0,
                won: false,
            },
        ],
    },
};

export const liveSeries = liveSeriesData;

export const finishedSeries = {
    seriesId: '1',
    tournament: {
        name: 'Nine to Five',
    },
    games: [
        {
            status: 'finished',
            mapName: 'Game 1',
            centeredInfoText: 'Game 1',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: false,
                tickingBackwards: false,
                color: 'red',
                currentSeconds: 7,
                occurredAt: '2021-12-06T12:00:00.000Z',
                publishDelay: 0,
            },
            momentum: [
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time', active: true },
                { color: 'white', contentText: '25' },
                { color: 'white', contentText: '26' },
                { color: 'white', contentText: '27' },
                { color: 'white', contentText: '28' },
                { color: 'white', contentText: '29' },
                { color: 'white', contentText: '30' },
            ],
            teams: [
                {
                    id: 1,
                    score: 3,
                    won: false,
                    infoText: { text: 'T', color: 'cs-tr' },
                },
                {
                    id: 2,
                    score: 2,
                    won: true,
                    infoText: { text: 'CT', color: 'cs-ct' },
                },
            ],
        },
        {
            status: 'finished',
            mapName: 'train',
            centeredInfoText: 'train',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: true,
                tickingBackwards: true,
                color: 'red',
                currentSeconds: 7,
                occurredAt: '2021-12-06T12:00:00.000Z',
                publishDelay: 0,
            },
            momentum: [
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time', active: true },
                { color: 'white', contentText: '25' },
                { color: 'white', contentText: '26' },
                { color: 'white', contentText: '27' },
                { color: 'white', contentText: '28' },
                { color: 'white', contentText: '29' },
                { color: 'white', contentText: '30' },
            ],
            teams: [
                {
                    id: 1,
                    score: 16,
                    won: true,
                    infoText: { text: 'T', color: 'cs-tr' },
                },
                {
                    id: 2,
                    score: 3,
                    won: false,
                    infoText: { text: 'CT', color: 'cs-ct' },
                },
            ],
        },
        {
            status: 'finished',
            mapName: 'dust2',
            centeredInfoText: 'dust2',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: false,
                tickingBackwards: false,
                color: 'red',
                currentSeconds: 7,
                occurredAt: '2021-12-06T12:00:00.000Z',
                publishDelay: 0,
            },
            momentum: [
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time', active: true },
                { color: 'white', contentText: '25' },
                { color: 'white', contentText: '26' },
                { color: 'white', contentText: '27' },
                { color: 'white', contentText: '28' },
                { color: 'white', contentText: '29' },
                { color: 'white', contentText: '30' },
            ],
            teams: [
                {
                    id: 1,
                    score: 10,
                    won: false,
                    infoText: { text: 'T', color: 'cs-ct' },
                },
                {
                    id: 2,
                    score: 16,
                    won: true,
                    infoText: { text: 'CT', color: 'cs-tr' },
                },
            ],
        },
    ],
    series: {
        startTimeDate: new Date(1482363367071).toISOString(),
        endTimeDate: '',
        status: 'finished',
        title: 'csgo',
        teams: [
            {
                id: '1',
                name: 'Secret',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1649',
                score: 1,
                won: false,
            },
            {
                id: '2',
                name: 'Virtus.Pro',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1749',
                score: 2,
                won: true,
            },
        ],
    },
};

export const pubg = {
    seriesId: '1',
    activeGameIndex: 1,
    tournament: {
        name: 'Nine to Five',
    },
    games: [
        {
            status: 'finished',
            mapName: 'vertigo',
            centeredInfoText: 'vertigo',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: false,
                tickingBackwards: false,
                color: 'red',
                currentSeconds: 7,
                occurredAt: '2021-12-06T12:00:00.000Z',
                publishDelay: 0,
            },
            momentum: [
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time', active: true },
                { color: 'white', contentText: '25' },
                { color: 'white', contentText: '26' },
                { color: 'white', contentText: '27' },
                { color: 'white', contentText: '28' },
                { color: 'white', contentText: '29' },
                { color: 'white', contentText: '30' },
            ],
            teams: [],
        },
        {
            status: 'live',
            mapName: 'vertigo',
            centeredInfoText: 'vertigo',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: true,
                tickingBackwards: true,
                color: 'red',
                currentSeconds: 7,
                occurredAt: '2021-12-06T12:00:00.000Z',
                publishDelay: 0,
            },
            momentum: [
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time' },
                { color: 'cs-tr.0', contentIcon: 'objectives/dead' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/bomb_explode' },
                { color: 'cs-tr.0', contentIcon: 'objectives/ancient' },
                { color: 'cs-tr.0', contentIcon: 'objectives/barrack' },
                { color: 'cs-tr.0', contentIcon: 'objectives/roshan' },
                { color: 'cs-tr.0', contentIcon: 'objectives/tower' },
                { color: 'cs-ct.0', contentIcon: 'objectives/defuse' },
                { color: 'cs-ct.0', contentIcon: 'objectives/time', active: true },
                { color: 'white', contentText: '25' },
                { color: 'white', contentText: '26' },
                { color: 'white', contentText: '27' },
                { color: 'white', contentText: '28' },
                { color: 'white', contentText: '29' },
                { color: 'white', contentText: '30' },
            ],
            teams: [],
        },
        {
            status: 'upcoming',
            mapName: 'vertigo',
            centeredInfoText: 'TBD',
            centeredInfoTextTranslation: { key: 'translation_key' },
            doughnut: [],
            gameClock: {
                isTicking: false,
                tickingBackwards: false,
                color: 'red',
                currentSeconds: 7,
                occurredAt: '2021-12-06T12:00:00.000Z',
                publishDelay: 0,
            },
            momentum: [],
            teams: [],
        },
    ],
    series: {
        startTimeDate: new Date(1482363367071).toISOString(),
        endTimeDate: '',
        status: 'live',
        title: 'csgo',
        teams: [
            {
                id: '1',
                name: 'Secret',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1649',
                score: 0,
                won: false,
            },
            {
                id: '2',
                name: 'Virtus.Pro',
                logoUrl: '/assets/images/teams/team-icon.png',
                teamColor: 'teams.1698',
                score: 1,
                won: false,
            },
        ],
    },
};
